<template>
  <div class="mt-4">
    <div class="my-4 d-flex align-items-center justify-content-between">
      <h1 class="page-title">{{ $t("editQRE.title") }}</h1>
      <b-button class="mr-2" variant="primary" pill @click="$router.go(-1)">
        <b-icon-arrow90deg-left class="mr-2" />
        {{ $t("back") }}
      </b-button>
    </div>

    <b-overlay
      :opacity="loading ? 0.95 : 1"
      :show="loading || finish"
      rounded="sm"
    >
      <b-card
        border-variant="light"
        class="shadow-sm card-rounded custom-card-padding"
      >
        <ValidationObserver ref="observer" v-slot="{ handleSubmit, invalid }">
          <b-form @submit.prevent="handleSubmit(editQRECheck)">
            <b-row>
              <b-col md>
                <ValidationProvider
                  v-slot="{ errors, pristine, valid }"
                  name="qre"
                  rules="required"
                >
                  <b-form-group>
                    <template v-slot:label>
                      <div class="d-flex align-content-center mb-1">
                        <p class="mb-0">
                          {{ $t("editQRE.qreName") }}
                          <span class="text-danger">*</span>
                        </p>
                        <p
                          v-b-tooltip.hover
                          v-b-tooltip.right
                          class="mb-0"
                          :title="$t('editQRE.editRestartsQre')"
                        >
                          <b-icon-bootstrap-reboot
                            class="mx-2 warning"
                            variant="warning"
                          />
                        </p>
                      </div>
                    </template>

                    <b-form-input
                      id="qre"
                      v-model="form.qre"
                      :placeholder="$t('editQRE.qreNamePlaceholder')"
                      :state="getValidationState(errors, valid, pristine)"
                      data-cy="edit-qre-name-input"
                    />
                    <b-form-invalid-feedback id="live-feedback-qre">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-alert v-model="gpuAlert" dismissible variant="danger">
                    <p v-for="alert in gpuAlertText" :key="alert" class="mb-0">
                      {{ alert }}
                    </p>
                  </b-alert>
                </ValidationProvider>
              </b-col>
            </b-row>

            <div class="divider my-4" />

            <b-row>
              <b-col md>
                <ValidationProvider
                  v-slot="{ errors, pristine, valid }"
                  name="Node"
                  rules="required"
                >
                  <b-form-group class="mt-3 mb-4">
                    <template v-slot:label>
                      <p class="mb-1">
                        {{ $t("editQRE.node") }}
                        <span class="text-danger">*</span>
                      </p>
                    </template>
                    <b-form-select
                      id="node"
                      v-model="form.node"
                      :options="nodes"
                      :state="getValidationState(errors, valid, pristine)"
                      disabled
                    />
                    <b-form-invalid-feedback id="live-feedback-node">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>

            <b-row>
              <b-col md>
                <ValidationProvider
                  v-slot="{ errors, pristine, valid }"
                  :rules="{
                    required: true,
                    max_value:
                      resources.availableCores !== null
                        ? resources.availableCores
                        : 16,
                    numeric: true,
                    min_value: 1,
                  }"
                  name="Cores"
                >
                  <b-form-group id="group-cores" class="mb-4" label-for="cores">
                    <template v-slot:label>
                      <p class="mb-1">
                        {{ $t("editQRE.cpus") }}
                        <span class="text-danger">*</span>
                      </p>
                    </template>
                    <b-form-input
                      id="cores"
                      v-model="form.cpu"
                      :disabled="disable"
                      :state="getValidationState(errors, valid, pristine)"
                      data-cy="edit-qre-cpus-expert-input"
                    >
                    </b-form-input>
                    <div
                      v-if="resources.availableCores !== null"
                      class="available-resources"
                    >
                      {{ $t("editQRE.availableCores") }}
                      <b>{{ resources.availableCores }}</b>
                    </div>
                    <b-form-invalid-feedback id="live-feedback-cores">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md>
                <ValidationProvider
                  v-slot="{ errors, pristine, valid }"
                  :rules="{
                    required: true,
                    regex: /^[\d]+([,.][\d]+)?\s*(B|KB|MB|GB|TB)$/i,
                    check_resource_size: {
                      totalResource: resources.availableMemoryInBytes,
                      minValue: 6291456,
                    },
                  }"
                  name="Memory"
                >
                  <b-form-group
                    id="group-memory"
                    class="mb-4"
                    label-for="memory"
                  >
                    <template v-slot:label>
                      <p class="mb-1">
                        {{ $t("editQRE.memoryExpert") }}
                        <span class="text-danger">*</span>
                      </p>
                    </template>
                    <div class="d-flex">
                      <div class="pr-2 flex-grow-1">
                        <b-form-input
                          id="memory"
                          v-model="form.memory"
                          :disabled="disable"
                          :state="getValidationState(errors, valid, pristine)"
                          data-cy="edit-qre-memory-expert-input"
                          :placeholder="$t('editQRE.unitsPlaceholderEx')"
                        />
                        <div
                          v-if="resources.availableMemory !== null"
                          class="available-resources"
                        >
                          {{ $t("editQRE.availableMemory") }}
                          <b>{{ resources.availableMemory }}</b>
                        </div>
                        <b-form-invalid-feedback id="live-feedback-memory">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>

            <ValidationProvider
              v-slot="{ errors, valid, pristine }"
              :customMessages="messages"
              :rules="{
                regex: /^(\s*\d+\s*(-\s*\d+\s*)?)(,\s*(\d+\s*(-\s*\d+\s*)?))*$/,
                cpu_set_cpus: { totalCpus: resources.totalCores - 1 },
              }"
              name="cpuSetCpus"
            >
              <b-form-group
                id="group-cpuSetCpus"
                class="mb-4"
                label-for="cpuSetCpus"
              >
                <template v-slot:label>
                  <p class="mb-1">
                    {{ $t("editQRE.cpuSetCpus") }}
                  </p>
                </template>
                <b-form-input
                  id="cpuSetCpus"
                  v-model="form.cpuSetCpus"
                  :disabled="disable"
                  :state="getValidationState(errors, valid, pristine)"
                  data-cy="edit-qre-cpus-set-cpus-input"
                  :placeholder="$t('editQRE.coresPlaceholderEx')"
                />
                <div
                  v-if="resources.totalCores !== null"
                  class="available-resources"
                >
                  {{ $t("editQRE.totalCores") }}
                  <b>{{ resources.totalCores }}</b>
                </div>
                <b-form-invalid-feedback id="live-feedback-cores">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

            <b-row>
              <b-col md>
                <ValidationProvider
                  v-slot="{ errors, valid, pristine }"
                  :rules="{
                    max_value: 5,
                    numeric: true,
                    min_value: 1,
                  }"
                  name="limit"
                >
                  <b-form-group id="group-limit" label-for="limit">
                    <template v-slot:label>
                      <p class="mb-1">
                        {{ $t("editQRE.limit") }}
                      </p>
                    </template>
                    <b-form-input
                      id="limit"
                      v-model="form.limit"
                      :disabled="disable"
                      :state="getValidationState(errors, valid, pristine)"
                      data-cy="edit-qre-limit-input"
                    >
                    </b-form-input>
                    <div
                      v-if="resources.snapshotLimit !== null"
                      class="available-resources"
                    >
                      <b> {{ resources.snapshotLimit }} </b>
                    </div>
                    <b-form-invalid-feedback id="live-feedback-limit">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col />
            </b-row>

            <div class="divider my-4" />

            <b-row>
              <b-col md>
                <ValidationProvider
                  v-slot="{ errors, valid, pristine }"
                  :rules="{
                    regex: /^[\d]+([,.][\d]+)?\s*(B|KB|MB|GB|TB)$/i,
                    check_resource_size: {
                      totalResource: resources.availableStorageInBytes,
                      minValue: 512,
                    },
                  }"
                  name="storage"
                >
                  <b-form-group
                    id="group-storage"
                    class="mt-3 mb-4"
                    label-for="storage"
                  >
                    <template v-slot:label>
                      <div class="d-flex align-content-center mb-1">
                        <p class="mb-0">
                          {{ $t("editQRE.storageExpert") }}
                        </p>
                        <p
                          v-b-tooltip.hover
                          v-b-tooltip.right
                          class="mb-0"
                          :title="$t('editQRE.editRestartsQre')"
                        >
                          <b-icon-bootstrap-reboot
                            class="mx-2 warning"
                            variant="warning"
                          />
                        </p>
                      </div>
                    </template>
                    <div class="d-flex">
                      <div class="pr-2 flex-grow-1">
                        <b-form-input
                          id="storage"
                          v-model="form.storage"
                          :disabled="disable"
                          :state="getValidationState(errors, valid, pristine)"
                          data-cy="edit-qre-storage-expert-input"
                          :placeholder="$t('editQRE.unitsPlaceholderEx')"
                        />
                        <div
                          v-if="resources.availableStorageInBytes !== null"
                          class="available-resources"
                        >
                          {{ $t("editQRE.availableStorage") }}
                          <b>{{ resources.availableStorage }}</b>
                        </div>
                        <b-form-invalid-feedback id="live-feedback-storage">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md>
                <ValidationProvider
                  v-slot="{ errors, valid, pristine }"
                  :rules="{
                    regex: /^[\d]+([,.][\d]+)?\s*(B|KB|MB|GB|TB)$/i,
                    check_resource_size: {
                      totalResource: 107374182400,
                      minValue: 1024,
                    },
                  }"
                  name="shm"
                >
                  <b-form-group
                    id="group-shm"
                    class="mt-3 mb-4"
                    label-for="shm"
                  >
                    <template v-slot:label>
                      <div class="d-flex align-content-center mb-1">
                        <p class="mb-0">
                          {{ $t("editQRE.shm") }}
                        </p>
                        <p
                          v-b-tooltip.hover
                          v-b-tooltip.right
                          class="mb-0"
                          :title="$t('editQRE.editRestartsQre')"
                        >
                          <b-icon-bootstrap-reboot
                            class="mx-2 warning"
                            variant="warning"
                          />
                        </p>
                      </div>
                    </template>
                    <div class="d-flex">
                      <div class="pr-2 flex-grow-1">
                        <b-form-input
                          id="shm"
                          v-model="form.shm"
                          :disabled="disable"
                          :state="getValidationState(errors, valid, pristine)"
                          data-cy="edit-qre-shm-expert-input"
                          :placeholder="$t('editQRE.unitsPlaceholderEx')"
                        />
                        <b-form-invalid-feedback id="live-feedback-shm">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>

            <b-row>
              <b-col md>
                <b-form-group>
                  <template v-slot:label>
                    <div class="d-flex align-content-center mb-1">
                      <p class="mb-0">{{ $t("editQRE.availableGpus") }}</p>
                      <p
                        v-b-tooltip.hover
                        v-b-tooltip.right
                        class="mb-0"
                        :title="$t('editQRE.editRestartsQre')"
                      >
                        <b-icon-bootstrap-reboot
                          class="mx-2 warning"
                          variant="warning"
                        />
                      </p>
                    </div>
                  </template>
                  <div v-if="form.availableToAssignFiltered.length > 0">
                    <b-table
                      :fields="fieldsGPUs"
                      :items="form.availableToAssignFiltered"
                      borderless
                      class="mb-0 md card-rounded"
                      data-cy="gpus-table-editQre"
                      fixed
                      head-variant="light"
                      hover
                      small
                      striped
                      @row-clicked="myRowClickHandler"
                    >
                      <template #cell(id)="data">
                        <div
                          class="d-flex align-items-center justify-content-center"
                          style="height: 40px"
                        >
                          <b-form-checkbox
                            v-model="form.assignedGpusId"
                            :value="data.value"
                            class="m-0"
                            inline
                          />
                        </div>
                      </template>

                      <template #cell(name)="data">
                        <p class="gpu-name mb-0">
                          {{ data.value }}
                        </p>
                      </template>

                      <template #cell(status)="data">
                        <GpuIcon
                          v-b-tooltip.hover
                          :color="
                            data.value === 'BROKEN' ? '#fa5c7c' : '#6FBF94'
                          "
                          :height="25"
                          :title="
                            data.value === 'BROKEN'
                              ? 'GPU is broken'
                              : 'GPU is connected'
                          "
                          :width="25"
                        />
                      </template>

                      <template #cell(empty)="data">
                        <b-button
                          v-b-tooltip.hover
                          :title="data.item.uuid"
                          pill
                          size="sm"
                          variant="light"
                          @click="copyToClipboard(data.item.uuid)"
                        >
                          <b-icon-clipboard />
                        </b-button>
                      </template>
                    </b-table>
                    <b-form-invalid-feedback
                      :class="gpuAlert ? 'd-block' : 'd-none'"
                    >
                      Please remove the broken GPUs
                    </b-form-invalid-feedback>
                  </div>
                  <p v-else>
                    <b-icon-exclamation-circle class="mr-1" />
                    {{ $t("editQRE.NoAvailableGpus") }}
                  </p>
                </b-form-group>
              </b-col>
            </b-row>

            <div class="divider my-4" />

            <div class="d-flex justify-content-end">
              <b-button
                :disabled="disable || invalid"
                variant="primary"
                data-cy="save-qre-button"
                pill
                type="submit"
              >
                {{ $t("editQRE.button") }}
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </b-card>

      <template #overlay>
        <div v-if="!finish" class="text-center">
          <b-spinner :label="$t('loading')" />
          <p>{{ $t("editQRE.wait") }}</p>
          <p v-if="!loading">
            {{ $t("editQRE.notFinished") }}
          </p>
        </div>

        <div v-else class="text-center">
          <p>{{ $t("editQRE.qreModified") }}</p>
          <p>{{ $t("editQRE.leavePage") }}</p>

          <b-button
            class="mr-2"
            variant="primary"
            data-cy="qre-edited-back-button"
            pill
            @click="$router.go(-1)"
          >
            <b-icon-arrow90deg-left class="mr-2" />
            {{ $t("back") }}
          </b-button>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import {
  required,
  numeric,
  regex,
  max_value,
  min_value,
} from "vee-validate/dist/rules";
import GpuIcon from "@/components/Icons/GpuIcon.vue";
import { byteToValueFormatter, valueToByteFormatter } from "@/util/formatters";

extend("required", required);
extend("numeric", numeric);
extend("regex", {
  ...regex,
  message: "Invalid format! Only '10GB, 1.45GB or 2,723GB' accepted",
});
extend("max_value", {
  ...max_value,
  message: "Value needs to be less than {max}",
});
extend("min_value", { ...min_value, message: "Value must be > 1" });

extend("cpu_set_cpus", {
  params: ["totalCpus"],
  validate: (value, { totalCpus }) => {
    let cpuSetArray = [];
    let errorMessage = "";
    if (value !== "") {
      // check if splitCpus contains ranges
      value.split(",").forEach((cores) => {
        // if range
        if (cores.includes("-")) {
          // check if values in range are ascending
          if (parseInt(cores.split("-")[0]) >= parseInt(cores.split("-")[1])) {
            errorMessage = "The values are not ascending";
          } else {
            for (
              let i = parseInt(cores.split("-")[0]);
              i <= parseInt(cores.split("-")[1]);
              i++
            ) {
              cpuSetArray.push(i.toString());
            }
          }
        } else {
          // else just push values to the array
          cpuSetArray.push(cores);
        }
      });
    }
    // check errors
    for (let i = 0; i < cpuSetArray.length; i++) {
      if (
        // check if same el is found at 2 different indexes
        cpuSetArray.indexOf(cpuSetArray[i]) !==
        cpuSetArray.lastIndexOf(cpuSetArray[i])
      ) {
        errorMessage = "The cores list contains duplicate values";
        break;
      }
      if (parseInt(cpuSetArray[i]) > totalCpus) {
        errorMessage = "The core maximum value is exceeded";
        break;
      }
    }
    return errorMessage !== "" ? errorMessage : true;
  },
});

extend("check_resource_size", {
  params: ["totalResource", "minValue"],
  validate: (value, { totalResource, minValue }) => {
    const valueBytes = valueToByteFormatter(value);

    if (valueBytes < minValue) {
      return "Value must be > " + byteToValueFormatter(minValue);
    }

    if (valueBytes > totalResource) {
      return (
        "Value needs to be less than " + byteToValueFormatter(totalResource)
      );
    }
    return true;
  },
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    GpuIcon,
  },
  name: "EditQRE",
  data() {
    return {
      qre: {},
      loading: true,
      gpuAlert: false,
      gpuAlertText: [],
      form: {
        qre: "",
        node: null,
        cpu: null,
        memory: null,
        storage: null,
        shm: null,
        cpuSetCpus: "",
        limit: null,
        availableToAssign: [],
        availableToAssignFiltered: [],
        assignedGpusId: [],
      },
      initial: {},
      nodes: [],
      finish: false,
      newRedirect: "",
      resources: {
        totalCores: null,
        availableCores: null,
        availableMemory: null,
        availableMemoryInBytes: null,
        availableStorage: null,
        availableStorageInBytes: null,
        snapshotLimit: null,
      },
      disable: true,
      messages: {
        regex:
          "Only ranges and/or lists of numbers are accepted ( accepted symbols: ' - ', ' , ' ) ",
      },
      fieldsGPUs: [
        {
          key: "id",
          label: "",
          thStyle: { width: "50px", minWidth: "50px !important" },
          tdClass: "align-middle disable-text-selection",
        },
        {
          key: "name",
          label: "Name",
          tdClass: "align-middle disable-text-selection",
        },
        {
          key: "status",
          label: "Status",
          thStyle: {
            width: "100px",
            minWidth: "100px !important",
          },
          tdClass: "align-middle disable-text-selection",
        },
        {
          key: "empty",
          label: "",
          thStyle: { width: "50px", minWidth: "50px !important" },
          tdClass: "align-middle disable-text-selection",
        },
      ],
    };
  },
  async mounted() {
    try {
      this.loading = true;
      const {
        data: { content: agents },
      } = await this.$http.get(
        this.$cfg.BASE_QRE_MANAGER_VERSION_URL + "/agents"
      );

      const { data: qre } = await this.$http.get(
        `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/qres/${this.$route.params.id}`
      );

      const { data: resources } = await this.$http.get(
        `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/agents/${qre.agentId}/hardware/resources`
      );

      this.form = {
        ...this.form,
        qre: qre.name,
        cpu: qre.cores,
        cpuSetCpus: qre.cpuSetCpus,
        memory: byteToValueFormatter(qre.memoryInBytes),
        storage:
          qre.storageInBytes !== 0 && qre.storageInBytes !== null
            ? byteToValueFormatter(qre.storageInBytes)
            : null,
        shm: qre.shmInBytes !== 0 ? byteToValueFormatter(qre.shmInBytes) : null,
        limit: qre.snapshotLimit,
        availableToAssign: resources.gpuResourceResponses,
        assignedGpusId: qre.gpus.map((el) => el.id),
      };
      this.form.availableToAssign.forEach((gpuToAssign) => {
        if (gpuToAssign.status === "BROKEN") {
          qre.gpus.forEach((gp) => {
            if (gp.uuid === gpuToAssign.uuid) {
              this.form.availableToAssignFiltered.push(gpuToAssign);
            }
          });
        } else {
          this.form.availableToAssignFiltered.push(gpuToAssign);
        }
      });

      // for displaying the error modal
      this.initial = {
        qre: qre.name,
        storage: qre.storageInBytes,
        shm: qre.shmInBytes,
        assignedGpusId: qre.gpus.map((el) => el.id),
      };
      // show available resources depending on the status of the qre
      this.resources = {
        totalCores: resources.totalCores,
        availableCores:
          resources.availableCores + (qre.status === "RUNNING" ? qre.cores : 0),
        snapshotLimit: resources.snapshotLimit,
        availableMemory: this.byteToValueFormatter(
          qre.status === "RUNNING"
            ? resources.availableMemoryInBytes + qre.memoryInBytes
            : resources.availableMemoryInBytes
        ),
        availableMemoryInBytes:
          resources.availableMemoryInBytes +
          (qre.status === "RUNNING" ? qre.memoryInBytes : 0),
        availableStorage: this.byteToValueFormatter(
          qre.status === "RUNNING"
            ? resources.availableStorageInBytes + qre.storageInBytes
            : resources.availableStorageInBytes
        ),
        availableStorageInBytes:
          resources.availableStorageInBytes +
          (qre.status === "RUNNING" ? qre.storageInBytes : 0),
      };
      agents.forEach((el) => {
        if (el.status === "UP")
          this.nodes.push({ value: el.id, text: el.name });
      });

      this.form.node = qre.agentId;

      this.gpuAlertText = qre.gpus.map((el) => {
        if (el.status === "BROKEN") {
          this.gpuAlert = true;
          return (
            "The GPU " +
            el.name +
            " with UUID: " +
            el.uuid +
            " is broken. Please unassign it!"
          );
        }
      });

      this.disable = !!agents.find(
        (el) => el.status === "IMAGE_MISSING" && el.id === qre.agentId
      );

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    } catch (err) {
      if (err.response) console.log(err.response);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    byteToValueFormatter,
    valueToByteFormatter,
    myRowClickHandler(record) {
      const exists = this.form.assignedGpusId.includes(record.id);

      if (exists) {
        this.form.assignedGpusId = this.form.assignedGpusId.filter(
          (el) => el !== record.id
        );
      } else {
        this.form.assignedGpusId.push(record.id);
      }
    },

    async editQRECheck() {
      // check if gpus array is the same
      const containsAll = (arr1, arr2) =>
        arr2.every((arr2Item) => arr1.includes(arr2Item));

      const sameMembers = (arr1, arr2) =>
        containsAll(arr1, arr2) && containsAll(arr2, arr1);

      const brokenGpus = [];

      // check if gpu is broken
      this.form.assignedGpusId.forEach((el) => {
        const found = this.form.availableToAssign.find((gpu) => gpu.id === el);
        if (found.status === "BROKEN") {
          brokenGpus.push(found);
        }
      });

      if (brokenGpus.length === 0) {
        this.gpuAlert = false;
      } else {
        this.gpuAlert = true;
        return;
      }

      // check if restart is necessary
      if (
        this.initial.qre !== this.form.qre ||
        this.initial.storage !==
          (valueToByteFormatter(this.form.storage) ?? 0) ||
        this.initial.shm !== (valueToByteFormatter(this.form.shm) ?? 0) ||
        !sameMembers(this.form.assignedGpusId, this.initial.assignedGpusId)
      ) {
        this.restartModal();
      } else {
        await this.editQRE();
      }
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    },

    restartModal() {
      this.$bvModal
        .msgBoxConfirm(
          "The modified values will restart the QRE. Proceed with the changes?",
          {
            size: "md",
            buttonSize: "sm",
            okVariant: "success",
            okTitle: "Yes",
            cancelTitle: "No",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.editQRE();
          }
        });
    },
    async editQRE() {
      try {
        this.loading = true;
        const { data } = await this.$http.post(
          `${this.$cfg.BASE_QRE_MANAGER_VERSION_URL}/qres/${this.$route.params.id}/`,
          {
            name: this.form.qre,
            agentId: this.form.node,

            qreHardwareResources: {
              cores: this.form.cpu,
              memoryInBytes: this.valueToByteFormatter(this.form.memory),
              storageInBytes: valueToByteFormatter(this.form.storage) ?? 0,
              shmInBytes: valueToByteFormatter(this.form.shm) ?? 0,
              cpuSetCpus: this.form.cpuSetCpus.replace(" ", ""),
              snapshotLimit: this.form.limit,
              selectedGpusIds: this.form.assignedGpusId,
            },
          },
          {
            errors: {
              400: () => true,
              409: () => true,
            },
          }
        );
        this.finish = true;
        this.newRedirect = data.qreId;
        this.$root.$bvToast.toast("QRE modified successfully", {
          title: `Success`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true,
        });
      } catch ({ response }) {
        this.loading = false;
        switch (response.status) {
          case 404:
            switch (response.data.errorCode) {
              case 0:
                this.$refs.observer.setErrors({
                  node: [response.data.message],
                });
                break;
              case 1:
                this.$refs.observer.setErrors({
                  image: [response.data.message],
                  imageExper: [response.data.message],
                });
                break;
            }
            break;

          case 409:
            switch (response.data.errorCode) {
              case 0:
                this.$refs.observer.setErrors({
                  qre: [response.data.message],
                });
                break;
              case 1:
                this.$refs.observer.setErrors({
                  cores: [response.data.message],
                });
                break;
              case 2:
                this.$refs.observer.setErrors({
                  memory: [response.data.message],
                });
                break;
              case 3:
                this.$refs.observer.setErrors({
                  storage: [response.data.message],
                });
                break;
              case 4:
                this.$refs.observer.setErrors({
                  limit: [response.data.message],
                });
                break;
            }
            break;

          case 400:
            if (!response.data && !response.data.length) {
              // error in case of type mismatch
              this.$bvToast.toast("Error", {
                title: `Something went wrong`,
                toaster: "b-toaster-bottom-right",
                variant: "danger",
              });
              break;
            }

            switch (response.data.errorCode) {
              case 0:
                this.$refs.observer.setErrors({
                  qre: [response.data.message],
                });
                break;
              case 1:
                this.$refs.observer.setErrors({
                  cpuSetCpus: [response.data.message],
                });
                break;
              case 3:
                this.$refs.observer.setErrors({
                  memory: [response.data.message],
                });
                break;
              default:
                console.log(response);
                this.$root.$bvToast.toast(response.data.message, {
                  title: `Something went wrong`,
                  toaster: "b-toaster-bottom-right",
                  variant: "danger",
                });
                break;
            }
            break;

          default:
            console.log(response);
            this.$root.$bvToast.toast(response.data.message, {
              title: `Something went wrong`,
              toaster: "b-toaster-bottom-right",
              variant: "danger",
            });
            break;
        }
      } finally {
        this.loading = false;
      }
    },
    getValidationState(errors, valid, pristine) {
      if (errors[0]) {
        return false;
      } else if (valid) {
        if (pristine) {
          return null;
        } else {
          return true;
        }
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped>
.available-resources {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}
</style>
